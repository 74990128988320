import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { DOMAIN_NAME } from "src/config";
// import { useTheme } from "@mui/material/styles";

import favicon_dark from "src/images/favicon/dark.png";
// import favicon_light from "src/images/favicon/light.png";

const Page = forwardRef(({ children, title = "", meta, ...other }, ref) => {
  // const favicon = localStorage.getItem("favicon");
  // const { palette } = useTheme();
  const { t } = useTranslation();

  // const isDark = palette.mode === "dark";

  return (
    <>
      <Helmet>
        <title>
          {t(title)} | {DOMAIN_NAME}
        </title>
        <link rel="apple-touch-icon" sizes="180x180" href={favicon_dark} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon_dark} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon_dark} />
        <link rel="shortcut icon" href={favicon_dark} type="image/png/ico" />
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
