import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// import useGetLogo from "./hooks/use-logo";
import { useTheme } from "@mui/material/styles";

import logo_dark from "src/images/logo/dark.png";
import logo_light from "src/images/logo/light.png";

const Logo = ({ disabledLink = false }) => {
  // const logo = useGetLogo();

  const { palette } = useTheme();

  const isDark = palette.mode === "dark";

  if (disabledLink) {
    return (
      <img
        alt=''
        style={{ width: "150px", height: "auto", maxHeight: "64px" }}
        src={isDark ? logo_dark : logo_light}
      />
    );
  }

  return (
    <RouterLink to="/">
      <img alt='' style={{ width: "150px", height: "auto" }} src={isDark ? logo_dark : logo_light} />
    </RouterLink>
  );
};

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
