import { RHFSelect } from "src/components/hook-form";
import Map from "src/components/map";
import { InputAdornment } from '@mui/material';
import Iconify from "src/components/Iconify";

import { useTranslation } from "react-i18next";
import { countries } from "./county-with-code";

const Countries = ({ type = "iso3", name, label, mobileVerified = false, ...rest }) => {
  const { t } = useTranslation();

  const renderer =
    type === "iso3"
      ? ({ name, iso3, phone_code, emoji }) => <option value={`${iso3}, ${phone_code}`}>+ {phone_code} - {name} {emoji}</option>
      : ({ name, iso3, phone_code, emoji }) => <option value={`${iso3}, ${phone_code}`}>+ {phone_code} - {name} {emoji}</option>;

  return (
    <RHFSelect
      {...rest}
      name={name ? name : "country"}
      label={label ? label : t("profile.choose_country")}
      disabled={mobileVerified}
      InputProps={
        mobileVerified
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Iconify icon={'hugeicons:tick-04'} style={{ color: '#008000' }} />
                </InputAdornment>
              ),
            }
          : {}
      }
    >
      <option value="" />
      <Map list={countries} render={renderer} />
    </RHFSelect>
  );
};

export default Countries;
