import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";

// const FundTransfer = Loadable(
//   lazy(() => import("src/pages/user/financial/fundTransfer"))
// );
// const EWallet = Loadable(
//   lazy(() => import("src/pages/user/financial/ewallet"))
// );
// const DepositWallet = Loadable(
//   lazy(() => import("src/pages/user/financial/deposit"))
// );
// const AddCredit = Loadable(
//   lazy(() => import("src/pages/user/financial/deposit/addCredit"))
// );
// const RequestPayout = Loadable(
//   lazy(() => import("src/pages/user/financial/requestPayout/index"))
// );


const PendingWalletDetail = Loadable(
  lazy(() => import("src/pages/user/financial/pendingWallet/detail"))
);

const PendingWallet = Loadable(
  lazy(() => import("src/pages/user/financial/pendingWallet/index"))
);

const PaymentHistoryDetail = Loadable(
  lazy(() => import("src/pages/user/financial/paymentHistory/detail"))
);

const PaymentHistory = Loadable(
  lazy(() => import("src/pages/user/financial/paymentHistory/index"))
);

const financial = {
  path: "financial",
  children: [
    { index: true, element: <Navigate to="pending-wallet" /> },
    // { path: "e-wallet", element: <EWallet /> },
    // {
    //   path: "deposit-wallet",
    //   children: [
    //     { index: true, element: <DepositWallet /> },
    //     { path: "add-credit", element: <AddCredit /> },
    //   ],
    // },
    // { path: "funds-transfer", element: <FundTransfer /> },
    // { path: "payout", element: <RequestPayout /> },
    {
      path: "pending-wallet",
      children: [
        { index: true, element: <PendingWallet /> },
        { path: ":week", element: <PendingWalletDetail /> },
      ],
    },
    {
      path: "payment-history",
      children: [
        { index: true, element: <PaymentHistory /> },
        { path: ":week", element: <PaymentHistoryDetail /> },
      ],
    },
    // { path: "pending-wallet", element: <PendingWallet /> },
    // { path: "payment-history", element: <PaymentHistory /> },
  ],
};

export default financial;
