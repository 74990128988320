import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

// const Ewallet = Loadable(
//   lazy(() => import("src/pages/admin/financial/ewallet"))
// );
// const DepositWallet = Loadable(
//   lazy(() => import("src/pages/admin/financial/deposit"))
// );
// const FundCredits = Loadable(
//   lazy(() => import("src/pages/admin/financial/fundCredit/index"))
// );
// const Payout = Loadable(
//   lazy(() => import("src/pages/admin/financial/payout/index"))
// );
// const PayoutRequest = Loadable(
//   lazy(() =>
//     import("src/pages/admin/financial/payout/components/request/index")
//   )
// );
// const PayoutHistory = Loadable(
//   lazy(() =>
//     import("src/pages/admin/financial/payout/components/history/index")
//   )
// );
// const FinancialReport = Loadable(
//   lazy(() =>
//     import("src/pages/admin/financial/financialReport/financialReport")
//   )
// );
// const LatestSales = Loadable(
//   lazy(() => import("src/pages/admin/financial/sales/index"))
// );

const PendingWallet = Loadable(
  lazy(() => import("src/pages/admin/financial/pendingWallet"))
);

const PendingWalletDetail = Loadable(
  lazy(() => import("src/pages/admin/financial/pendingWallet/detail"))
);

const PaymentHistory = Loadable(
  lazy(() => import("src/pages/admin/financial/paymentHistory"))
);

const PaymentHistoryDetail = Loadable(
  lazy(() => import("src/pages/admin/financial/paymentHistory/detail"))
);

const financial = [
  {
    path: "financial",
    children: [
      { element: <Navigate to="pending-wallet" />, index: true },
      // { path: "e-wallet", element: <Ewallet /> },
      // { path: "deposit-wallet", element: <DepositWallet /> },
      // { path: "fund-credits", element: <FundCredits /> },
      // {
      //   path: "payout",
      //   element: <Payout />,
      //   children: [
      //     { index: true, element: <Navigate to="request" replace /> },
      //     { path: "request", element: <PayoutRequest /> },
      //     { path: "history", element: <PayoutHistory /> },
      //   ],
      // },
      // { path: "report", element: <FinancialReport /> },
      // { path: "sales", element: <LatestSales /> },
      {
				path: "pending-wallet",
				children: [
					{ index: true, element: <PendingWallet /> },
					{ path: ":week", element: <PendingWalletDetail /> },
				],
			},
      {
				path: "payment-history",
				children: [
					{ index: true, element: <PaymentHistory /> },
					{ path: ":week", element: <PaymentHistoryDetail /> },
				],
			},
      // { path: "pending-wallet", element: <PendingWallet /> },
      // { path: "payment-history", element: <PaymentHistory /> },
    ],
  },
];

export default financial;
