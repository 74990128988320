import { Box, Container, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import useGetLogo from "src/components/logo/hooks/use-logo";

import logo_dark from "src/images/logo/dark.png";
import logo_light from "src/images/logo/light.png";

const AuthLayout = ({ children, login = false }) => {
    // const logo = useGetLogo();
    const { palette } = useTheme();

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                minHeight: "100vh",
                background: palette.background.main,
                py: "150px",
            }}
        >
            <Container
                fixed
                maxWidth={login ? "sm" : "md"}
                sx={{
                    height:"100%",
                    textAlign: "center",
                }}
            >
                <Paper variant="outlined" sx={{ padding: 3 }}>
                    <Box
                        sx={{
                            display: "flex",
                            mb: 3,
                            justifyContent: "center",
                        }}
                    >
                        <img
                            alt='logo'
                            style={{ width: "150px", height: "auto" }}
                            src={palette.mode === "dark" ? logo_dark : logo_light}
                        />
                    </Box>
                    {children}
                </Paper>
            </Container>
        </Box>
    );
};

export default AuthLayout;
